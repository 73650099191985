import { track as mixpanelTrack, init as mixpanelInit } from "mixpanel-browser";

const isInitialized = false;

type TrackArgs = Parameters<typeof mixpanelTrack>;
type InitArgs = Parameters<typeof mixpanelInit>;

export const track = (...args: TrackArgs) => {
  if (isInitialized) {
    mixpanelTrack(...args);
  }
};

export const init = (...args: InitArgs) => {
  if (!isInitialized) {
    mixpanelInit(...args);
  }
};
