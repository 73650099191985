import mapboxgl from "mapbox-gl";
import { useCallback, useLayoutEffect } from "react";
import { COLORS, MAP_CONFIG } from "../constants";
import { Step } from "../types";
import { allWaysGeoJson } from "../graph/path-graph";
import osmData from "../data/osm/processed.json";

mapboxgl.accessToken =
  "pk.eyJ1IjoiYWJlamZlaHIiLCJhIjoiY2oybm04eWo4MDF0cTMycXd3aGMycXhtbyJ9.NQzVrA6Y36jHic4nsFtU6Q";

let map: mapboxgl.Map | undefined;

const baseWidth = 15; // 20px
const baseZoom = 19; // zoom 10

// TODO: Move this to utils
function calculateBearing(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
) {
  function degreesToRadians(degrees: number) {
    return (degrees * Math.PI) / 180;
  }

  function radiansToDegrees(radians: number) {
    return (radians * 180) / Math.PI;
  }

  lat1 = degreesToRadians(lat1);
  lon1 = degreesToRadians(lon1);
  lat2 = degreesToRadians(lat2);
  lon2 = degreesToRadians(lon2);

  const dLon = lon2 - lon1;

  const y = Math.sin(dLon) * Math.cos(lat2);
  const x =
    Math.cos(lat1) * Math.sin(lat2) -
    Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
  let brng = Math.atan2(y, x);

  brng = radiansToDegrees(brng);
  return (brng + 360) % 360;
}

const DEBUG_WAYS = false;

let geolocationControl: mapboxgl.GeolocateControl;

// TODO: Find out how to get user location on iOS without this gross thing
// I would use Position for the return type, but mapbox doesn't export it
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getLastKnownLocation = (): any | undefined => {
  // @ts-expect-error undocumented APIs
  const position = geolocationControl?._lastKnownPosition;
  // @ts-expect-error undocumented APIs
  if (position && !geolocationControl._isOutOfMapMaxBounds(position)) {
    return position;
  }
  return undefined;
};

export const useMap = () => {
  const renderDebugPoints = useCallback(() => {
    const existingFootways = map?.getSource("path--footways");

    const footwaysFeatureData = allWaysGeoJson.map((geoJsonSource) => ({
      type: "Feature" as const,
      geometry: {
        type: "LineString" as const,
        coordinates: geoJsonSource as Array<Array<number>>,
      },
      properties: {},
    }));

    if (existingFootways && "setData" in existingFootways) {
      existingFootways.setData({
        type: "FeatureCollection",
        features: footwaysFeatureData,
      });
    } else {
      map?.addSource(`path--footways`, {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: footwaysFeatureData,
        },
      });

      // This layer is always on, it's our custom rendering of the footways in the PATH
      map?.addLayer({
        id: `path--footways`,
        type: "line",
        source: `path--footways`,
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "hsl(220, 0%, 87%)",
          "line-width": {
            type: "exponential",
            base: 1.5,
            stops: [
              [15, 1],
              [18, 4],
            ],
          },
        },
      });
    }

    if (!DEBUG_WAYS) {
      return;
    }

    const ways = osmData.elements.filter(
      (e) => e.type === "way" && !!e.tags?.highway,
    );

    const allNodes = ways
      .map((way) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return way.nodes?.map((nId: any) =>
          osmData.elements.find((e) => `${e.id}` === `${nId}`),
        );
      })
      .flat();

    map?.addSource("debug-nodes--ids", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: allNodes.map((node) => ({
          type: "Feature" as const,
          geometry: {
            type: "Point" as const,
            coordinates: [node!.lon, node!.lat] as [number, number],
          },
          properties: {
            title: `${node!.id}`,
          },
        })),
      },
    });

    map?.addLayer({
      id: "debug-nodes--ids",
      type: "symbol",
      source: "debug-nodes--ids",
      paint: {
        // 'circle-color': 'red',
        // 'circle-radius': 3,
      },
      layout: {
        // "icon-image": "custom-marker",
        // get the title name from the source's "title" property
        "text-field": ["get", "title"],
        "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
        "text-offset": [0, 1.25],
        "text-anchor": "top",
      },
    });
  }, []);

  useLayoutEffect(() => {
    if (!map) {
      map = new mapboxgl.Map(MAP_CONFIG);

      geolocationControl = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true,
        showAccuracyCircle: true,
      });

      map?.addControl(geolocationControl);

      map.on("load", () => {
        geolocationControl.trigger();

        renderDebugPoints();
      });
    }
  }, [renderDebugPoints]);

  const renderDirections = useCallback(
    (steps: Array<Step>, fromTitle?: string, toTitle?: string) => {
      const points = (() => {
        if (steps.length <= 2) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return [] as Array<any>;
        }

        const fromPoint = { ...steps[0].from, title: fromTitle };
        const toPoint = { ...steps[steps.length - 1].to, title: toTitle };
        return [fromPoint, toPoint];
      })();

      // useDrawMapPoints(map, "points", points, pointsConfig);
      if (!points.length) {
        return;
      }

      const existingPointSource = map?.getSource("points");

      // Fit map to bounds
      // Don't do this because we zoom into each step
      // map?.fitBounds(
      //   points.map((d) => [d.lon, d.lat]) as [
      //     [number, number],
      //     [number, number],
      //   ],
      //   {
      //     offset: [0, 0],
      //     padding: 150,
      //   },
      // );

      const featureData = points.map((point) => ({
        type: "Feature" as const,
        geometry: {
          type: "Point" as const,
          coordinates: [point.lon, point.lat],
        },
        properties: {
          title: point.title,
        },
      }));

      if (existingPointSource && "setData" in existingPointSource) {
        existingPointSource.setData({
          type: "FeatureCollection",
          features: featureData,
        });
      } else {
        map?.addSource("points", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: featureData,
          },
        });

        map?.addLayer({
          id: "points",
          source: "points",
          ...{
            type: "symbol",
            layout: {
              "icon-image": "custom-marker",
              // get the title name from the source's "title" property
              "text-field": ["get", "title"],
              "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
              "text-offset": [0, 1.25],
              "text-anchor": "top",
            },
          },
        });
      }

      // Render the footway/stair lines

      // Break down steps into series of nodes with stairs and without stairs
      // const { withStairs: stairs, withoutStairs: footways } = splitByStairs(steps);
      const stairs = steps.filter((s: Step) => s.stairs === true);
      const footways = steps.filter((s: Step) => s.stairs === false);

      // useDrawMapLines(map, "directions-footways", footways, stairlessStepsConfig);
      if (!steps.length) {
        return;
      }

      const existingFootwaySource = map?.getSource("directions-footways");

      if (existingFootwaySource && "setData" in existingFootwaySource) {
        existingFootwaySource.setData({
          type: "FeatureCollection" as const,
          features: footways.map((step) => {
            return {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: [
                  [step.from.lon, step.from.lat],
                  [step.to.lon, step.to.lat],
                ],
              },
              id: `${step.from.id}`,
              properties: {},
            };
          }),
        });
      } else {
        map?.addSource("directions-footways", {
          type: "geojson",
          data: {
            type: "FeatureCollection" as const,
            features: footways.map((step) => {
              return {
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: [
                    [step.from.lon, step.from.lat],
                    [step.to.lon, step.to.lat],
                  ],
                },
                id: `${step.from.id}`,
                properties: {},
              };
            }),
          },
        });

        map?.addLayer({
          id: "directions-footways",
          source: "directions-footways",
          ...{
            type: "line",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": [
                "case",
                ["boolean", ["feature-state", "hover"], false],
                COLORS.GREEN,
                COLORS.DARKGRAY,
              ],
              "line-width": {
                type: "exponential",
                base: 2,
                stops: [
                  [0, baseWidth * Math.pow(2, 0 - baseZoom)],
                  [24, baseWidth * Math.pow(2, 24 - baseZoom)],
                ],
              },
            },
          },
        });
      }

      // useDrawMapLines(map, "directions-stairs", stairs, stairStepsConfig);
      if (!steps.length) {
        return;
      }

      const existingStairsSource = map?.getSource("directions-stairs");

      if (existingStairsSource && "setData" in existingStairsSource) {
        existingStairsSource.setData({
          type: "FeatureCollection" as const,
          features: stairs.map((step) => {
            return {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: [
                  [step.from.lon, step.from.lat],
                  [step.to.lon, step.to.lat],
                ],
              },
              id: `${step.from.id}`,
              properties: {},
            };
          }),
        });
      } else {
        map?.addSource("directions-stairs", {
          type: "geojson",
          data: {
            type: "FeatureCollection" as const,
            features: stairs.map((step) => {
              return {
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: [
                    [step.from.lon, step.from.lat],
                    [step.to.lon, step.to.lat],
                  ],
                },
                id: `${step.from.id}`,
                properties: {},
              };
            }),
          },
        });

        map?.addLayer({
          id: "directions-stairs",
          source: "directions-stairs",
          ...{
            type: "line",
            layout: {
              "line-join": "round",
            },
            paint: {
              "line-color": [
                "case",
                ["boolean", ["feature-state", "hover"], false],
                COLORS.GREEN,
                COLORS.DARKGRAY,
              ],
              "line-dasharray": [0.3, 0.5],
              // Special function to ensure line width zooms with the map
              "line-width": {
                type: "exponential",
                base: 2,
                stops: [
                  [0, baseWidth * Math.pow(2, 0 - baseZoom)],
                  [24, baseWidth * Math.pow(2, 24 - baseZoom)],
                ],
              },
            },
          },
        });
      }
    },
    [],
  );

  // Intended to clear the old directions from the map
  const clearDirections = useCallback(() => {
    // Noop until all the issues with it are fixed
    // map?.removeLayer("points");
    // }
    // if (map?.getSource("directions-stairs")) {
    //   map?.removeSource("directions-stairs");
    // map?.removeLayer("directions-stairs");
    // }
    // if (map?.getSource("directions-footways")) {
    //   map?.removeSource("directions-footways");
    // map?.removeLayer("directions-footways");
    // }
    // if (map?.getSource("path--footways")) {
    //   map?.removeSource("path--footways");
    //   map?.removeLayer("path--footways");
    // }
  }, []);

  const highlightStep = useCallback((step: Step) => {
    map?.fitBounds(
      new mapboxgl.LngLatBounds([
        step.from.lon,
        step.from.lat,
        step.to.lon,
        step.to.lat,
      ]),
      {
        duration: 1200,
        zoom: 19,
        bearing: calculateBearing(
          step.from.lat,
          step.from.lon,
          step.to.lat,
          step.to.lon,
        ),
        pitch: 50,
        // seems to be needed, despite the map being the right size and using "fitBounds"
        offset: [0, -150],
      },
    );
    const source = map?.getSource(
      step.stairs ? "directions-stairs" : "directions-footways",
    );

    if (!source) {
      return () => {};
    }

    map?.setFeatureState(
      {
        source: step.stairs ? "directions-stairs" : "directions-footways",
        id: `${step.from.id}`,
      },
      { hover: true },
    );

    return () => {
      map?.setFeatureState(
        {
          source: step.stairs ? "directions-stairs" : "directions-footways",
          id: `${step.from.id}`,
        },
        { hover: false },
      );
    };
  }, []);

  return { renderDirections, clearDirections, highlightStep };
};
