// prettier-ignore
export const STEP_DATA: Record<
  string,
  string
> = {
  // Royal Bank Plaza
  "3709249211-3584705936": "Proceed down the hall and turn left at the escalators",
  "3584705936-391005487": "Proceed down the hall past the atrium",
  "3367211197-3715805185": 'Proceed down the hall past a set of escalators',
  "3367211199-3367211200": 'Proceed through the doors marked "Toronto-Dominon Centre" toward the escalators',
  "3367211199-3715805185": 'Proceed through the doors marked "Royal Bank Plaza" and taking a slight right',
  "3367211197-391005487": "Proceed down the hall toward a set of escalators",
  "391005487-3584705936": "Turn left at the escalators and proceed down the hall",
  "391005486-3559397338": 'Proceed down the hall toward the doors marked "Union Station"',

  // Toronto Dominion Bank Tower
  "3367211200-3367211204": "Take the escalator up",
  "3367211207-391005488": 'Proceed through the doors',
  "391005488-3367211211": "Proceed down the hallway",
  "3700500645-697805106-3698533591": 'Proceed down the hallway and turn right at the sign for "Commerce Court"',
  "3700500645-697805106": 'Proceed down the hallway',
  "3700500645-3367211211": "Proceed down the hallway",
  "3367211211-391005488": "Proceed down the hallway",
  "3367211207-3367211204": 'Proceed through doors marked "Wellington Street" toward the escalators',
  "3367211204-3367211200": "Take the escalator down",

  // Waterpark Plaza
  "3482106456-3356985811-3482106457": "Proceed through the food court and turn right at the pillar",
  "3482106456-3356985811": "Proceed through the food court",
  "3482106457-3703720702": "Proceed up the stairs and through the set of doors",
  "5943305398-3019224555": "Take the stairs down and proceed through the set of doors",
  "11256458146-3019224554": "Proceed down the hallway past the shops",
  "3703720702-3019224554": "Proceed through the skywalk over Harbour Street",
  "5943305395-11256458146": "Proceed through the set of doors and take the stairs up",
  "3019224554-11256458146": "Proceed down the hallway past the shops",
  "3019224555-10794053447": "Proceed through the skywalk over Lake Shore Blvd",
  "10794053447-3019224555": "Proceed through the skywalk over Lake Shore Blvd",
  "3356985811-3482106456": "Proceed through the food court",
  "3482106457-3356985811": "Take the stairs down and proceed down the hallway",
  "3019224554-3703720702": "Proceed through the skywalk over Harbour Street",
};
