import osmData from "../data/osm/processed.json";
import { SearchResult } from "../types";
import { useMemo, useRef } from "react";
import { track } from "../services/mixpanel";
import { getDirections } from "../utils/get-directions";

/**
 * Handle pathfinding and rendering directions on the map
 */
export const useDirections = (from?: SearchResult, to?: SearchResult) => {
  const cost = useRef<undefined | number>();

  const convertedSteps = useMemo(() => {
    const { cost: calculatedCost, path: resolvedPath } = getDirections(
      from,
      to,
    );

    if (from && to) {
      track("Get Directions", {
        From: from.title,
        To: to.title,
      });
    }

    if (!resolvedPath) {
      return [];
    }

    cost.current = calculatedCost;

    const steps = [];

    for (let i = 0; i < resolvedPath.length - 1; ++i) {
      const fromNodeId = resolvedPath[i];
      const toNodeId = resolvedPath[i + 1];

      const fromNode = osmData.elements.find(
        (e) => e.type === "node" && `${e.id}` === `${fromNodeId}`,
      );
      const toNode = osmData.elements.find(
        (e) => e.type === "node" && `${e.id}` === `${toNodeId}`,
      );

      const way = osmData.elements.find(
        (e) =>
          e.type === "way" &&
          e.nodes.includes(Number(fromNodeId)) &&
          e.nodes.includes(Number(toNodeId)),
      );
      const stairs = way.tags?.highway === "steps";

      steps.push({
        from: {
          id: `${fromNode.id}`,
          lat: fromNode.lat,
          lon: fromNode.lon,
        },
        to: {
          id: `${toNode.id}`,
          lat: toNode.lat,
          lon: toNode.lon,
        },
        stairs,
        wayId: way.id,
      });
    }

    return steps;
  }, [from, to]);

  if (!from || !to) {
    return { steps: [], distanceInMetres: undefined };
  }

  return { steps: convertedSteps, distanceInMetres: cost.current };
};
