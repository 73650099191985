import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import distanceFrom from "distance-from";
import osmData from "./data/osm/processed.json";
import "./App.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { DirectionsBox } from "./components/directions-box/directions-box.component";
import searchData from "./data/computed/search-data.json";
import { useDirections } from "./hooks/use-directions";
import { getLastKnownLocation, useMap } from "./hooks/use-map";
import { NodeID, SearchResult, SearchResultWithDistance, Step } from "./types";
import { init } from "./services/mixpanel";
import { getFormattedTimeForDistance } from "./utils/readable-distance";
import { getNearestNodeIdFromLatLng } from "./graph/path-graph";

const formatDistance = (distance: number) =>
  distance / 1000 < 1
    ? `${Number(distance).toFixed(0)} m`
    : `${(distance / 1000).toFixed(2)} km`;

const LOCATION_SUGGESTION = {
  id: "user_location",
  title: "Current Location",
  nodeIDs: [],
};

if (process.env.NODE_ENV === "production") {
  init("eb417ce587a4286aef69c80fd5baaa23", {
    debug: true,
    track_pageview: true,
    persistence: "localStorage",
  });
}

const makeBidirectional = (nodeList: Array<Array<NodeID>>) => {
  const reversedList = nodeList.map((sequence: Array<NodeID>) => {
    const _sequence = [...sequence];
    return _sequence.reverse();
  });

  return [...nodeList, ...reversedList];
};

// These are NodeIDs
// TODO: Precompute this at build time so it doesn't happen during app load
const compositeNodes = makeBidirectional([
  // Royal Bank Plaza
  // The south end of Royal Bank Plaza
  [
    "3559397338",
    "391005486",
    "3555217563",
    "3709493355",
    "3700500629",
    "3709249211",
    "3584705936",
  ],
  // Right before the doors out of Royal Bank Plaza
  ["391005487", "3367211197", "3715805185"],
  // The doorway and the escalator landing at Royal Bank Plaza
  ["3715805185", "3367211199", "3367211200"],

  // Toronto Dominion Bank Tower
  // Two halls right outside the doors above the escalator
  ["3367211204", "3367211207", "391005488"],
  // Two halls segmented by a useless escalator
  ["3367211211", "3700500645", "697805106"],

  // The stairs before the skywalk in Harbour Plaza Condos to Scotiabank Arena
  [
    "11256458146",
    "5943305395",
    "5943305399",
    "5943305400",
    "5943305398",
    "3019224555",
  ],

  // RBC Waterpark Plaza
  ["3356985811", "3482106457", "3703720702"],
]);

function groupSteps(steps: Array<Step>, groups: Array<Array<NodeID>>) {
  const groupedSteps: Array<Array<Step>> = [];
  let currentGroup: Array<Step> = [];

  // iterate over steps
  for (let i = 0; i < steps.length; ++i) {
    const step = steps[i];

    const _currentGroup = [...currentGroup, step];

    const allNodeIdsWithDupes = _currentGroup
      .map((s) => [s.from.id, s.to.id])
      .flat();
    const comparisonNodeIdString = [...new Set(allNodeIdsWithDupes)].join(",");

    const isMatch = groups.some((sequence) => {
      return (
        (sequence.join(",").startsWith(comparisonNodeIdString) &&
          sequence.length - 1 > _currentGroup.length) ||
        (sequence.join(",") === comparisonNodeIdString &&
          sequence.length - 1 === _currentGroup.length) ||
        i === 0
      );
    });

    // If there is no set in groups that starts with the same set of IDs as the current group, then it must not be a group so we'll create a new group for next time
    if (!isMatch) {
      groupedSteps.push(currentGroup);
      currentGroup = [step];
    } else {
      currentGroup.push(step);

      // if we're at the end, add the current group to the groupedSteps
    }

    if (i === steps.length - 1) {
      groupedSteps.push(currentGroup);
    }
  }

  return groupedSteps;
}

function App() {
  const [fromNode, setFromNode] = useState<SearchResult | undefined>(() => {
    // Initialize from the 'start' query param if it exists
    const urlParams = new URLSearchParams(window.location.search);
    const fromID = urlParams.get("start") ?? undefined;

    return fromID
      ? {
          id: fromID,
          title: "Your Location",
          nodeIDs: [fromID],
        }
      : undefined;
  });
  const [toNode, setToNode] = useState<SearchResult | undefined>();

  const [fromText, setFromText] = useState(fromNode?.title ?? "");
  const [toText, setToText] = useState(toNode?.title ?? "");

  const [searchActive, setSearchActive] = useState<"to" | "from" | undefined>();
  const [suggestions, setSuggestions] = useState<
    Array<SearchResultWithDistance>
  >([]);

  const { steps, distanceInMetres } = useDirections(fromNode, toNode);

  const groupedSteps = groupSteps(steps, compositeNodes);

  const { renderDirections, clearDirections } = useMap();

  useEffect(() => {
    renderDirections(steps, fromNode?.title, toNode?.title);
  }, [fromNode?.title, renderDirections, steps, toNode?.title]);

  const handleFocus = useCallback((active: "to" | "from") => {
    setSearchActive(active);

    if (active === "from") {
      setSuggestions(getLastKnownLocation() ? [LOCATION_SUGGESTION] : []);
    } else {
      setSuggestions([]);
    }
  }, []);

  const minuteText = useMemo(() => {
    if (!distanceInMetres) {
      return;
    }

    return getFormattedTimeForDistance(distanceInMetres);
  }, [distanceInMetres]);

  const handleInput = useCallback(
    (which: "from" | "to") => (e: ChangeEvent<HTMLInputElement>) => {
      let _suggestions: Array<SearchResultWithDistance> = [];
      const value = e.target.value;
      if (which === "from") {
        setFromText(value);
      }
      if (which === "to") {
        setToText(value);
      }
      if (value.length > 0) {
        const regex = new RegExp(`^${value}`, `i`);
        _suggestions = searchData
          .filter(
            (item) =>
              regex.test(item.title) ||
              item.keywords?.some((kw) => regex.test(kw)),
          )
          .map((item) => {
            const fromNodeFR =
              which === "to"
                ? osmData.elements.find(
                    // TODO: Use the midpoint of all nodes instead of whatever this is
                    (e) =>
                      e.type === "node" && `${e.id}` === fromNode?.nodeIDs[0],
                  )
                : undefined;

            const originLat =
              which === "from"
                ? getLastKnownLocation()?.coords.latitude
                : fromNodeFR?.lat;
            const originLon =
              which === "from"
                ? getLastKnownLocation()?.coords.longitude
                : fromNodeFR?.lon;

            const suggestionNode = osmData.elements.find(
              // TODO: Use the midpoint of all nodes instead of whatever this is
              (e) => e.type === "node" && `${e.id}` === item.nodeIDs[0],
            );

            if (
              originLat &&
              suggestionNode?.lat === originLat &&
              originLon &&
              suggestionNode?.lon === originLon
            ) {
              return {
                ...item,
                distance: 0,
              };
            }

            const distance =
              suggestionNode && originLat && originLon
                ? distanceFrom([suggestionNode.lat, suggestionNode.lon])
                    .to([originLat, originLon])
                    .in("m")
                : undefined;

            return {
              ...item,
              distance,
            };
          });
      }

      if (
        (which === "from" && getLastKnownLocation()) ||
        (which === "to" && !!fromNode)
      ) {
        _suggestions.sort((a, b) => {
          if (!a.distance) {
            return Infinity;
          }
          if (!b.distance) {
            return Infinity;
          }
          return a.distance - b.distance;
        });
      }

      if (which === "from" && value.length === 0) {
        setSuggestions(
          getLastKnownLocation()
            ? [LOCATION_SUGGESTION, ..._suggestions]
            : _suggestions,
        );
      } else {
        setSuggestions(_suggestions);
      }
    },
    [fromNode],
  );

  const handleSuggestionClicked = useCallback(
    (value: SearchResult) => {
      if (searchActive === "to") {
        setToText(
          value.subtitle ? value.title + ", " + value.subtitle : value.title,
        );
        setToNode(value);
      }

      if (searchActive === "from") {
        setFromText(
          value.subtitle ? value.title + ", " + value.subtitle : value.title,
        );
        if (value.id !== "user_location") {
          setFromNode(value);
        } else {
          if (!localStorage.getItem("location_disclaimer")) {
            confirm(
              "Navigation from your current location may not work accurately, and only works well if you're already in the PATH",
            );
            localStorage.setItem("location_disclaimer", "true");
          }
          const location = getLastKnownLocation();
          const positionLatLon = [
            location.coords.latitude,
            location.coords.longitude,
          ];

          const nodeIDs = [
            getNearestNodeIdFromLatLng(positionLatLon as [number, number]),
          ].map(String);
          setFromNode({
            ...value,
            nodeIDs,
          });
        }
      }
      setSearchActive(undefined);
      setSuggestions([]);
    },
    [searchActive],
  );

  const handleFlipDirections = useCallback(() => {
    const oldFromNode = fromNode;
    const oldFromText = fromText;

    setFromNode(toNode);
    setFromText(toText);

    setToNode(oldFromNode);
    setToText(oldFromText);
  }, [fromText, toText, fromNode, toNode]);

  return (
    <div className="App">
      <div className="grid-search">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              flex: 1,
            }}
          >
            <div className="input-container">
              <input
                type="text"
                value={fromText}
                placeholder="From"
                onFocus={() => handleFocus("from")}
                onInput={handleInput("from")}
              />
              {fromNode ? (
                <button
                  onClick={() => {
                    setFromText("");
                    setFromNode(undefined);
                    clearDirections();
                  }}
                >
                  &times;
                </button>
              ) : null}
            </div>
            <div className="input-container">
              <input
                type="text"
                value={toText}
                placeholder="To"
                onFocus={() => handleFocus("to")}
                onInput={handleInput("to")}
              />
              {toNode ? (
                <button
                  onClick={() => {
                    setToText("");
                    setToNode(undefined);
                    clearDirections();
                  }}
                >
                  &times;
                </button>
              ) : null}
            </div>
          </div>
          <button onClick={handleFlipDirections}>⇅</button>
        </div>
        {distanceInMetres ? (
          <div className="info-text">
            {minuteText} ({formatDistance(distanceInMetres)})
          </div>
        ) : null}
      </div>
      <div className="grid-map" id="map" />
      <div className="grid-navigation">
        <DirectionsBox groupedSteps={groupedSteps} />
      </div>
      {searchActive && (
        <div
          className="grid-searchresults"
          onClick={() => {
            setSearchActive(undefined);
          }}
        >
          <ul>
            {(suggestions || ([] as Array<SearchResult>)).map((suggestion) => (
              <li
                key={suggestion.id}
                onClick={() => handleSuggestionClicked(suggestion)}
              >
                <div className="search-result__title">{suggestion.title}</div>
                <div className="search-result__subtitle">
                  {suggestion.subtitle}
                  {suggestion.subtitle && suggestion.distance ? " • " : ""}
                  {suggestion.distance
                    ? formatDistance(suggestion.distance)
                    : ""}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default App;
