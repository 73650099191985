import { useCallback, useEffect } from "react";
import { useMemo } from "react";
import { NodeID, Step } from "../../types";
import { wayById } from "../../utils/way-by-id";
import osmData from "../../data/osm/processed.json";
import { isPointInPolygon } from "../../utils/point-in-polygon";
import { useMap } from "../../hooks/use-map";
import { last } from "lodash";
import { STEP_DATA } from "../../data/manual-entry/step-data";

const LEVEL_NAMES: Record<string, Record<string, string>> = {
  "CF Toronto Eaton Centre": {
    "-3": "Urban Eatery",
    "-2": "Level 1",
    "-1": "Level 2",
    "0": "Level 3",
  },
  "Toronto Dominion Bank Tower": {
    "-1": "Concourse",
  },
  "Hudson's Bay/Saks Fifth Avenue": {
    "-1": "Lower Level",
  },
  "Scotia Plaza": {
    "-1": "Concourse",
  },
  "Commerce Court West": {
    "-1": "Concourse",
  },
  "Commerce Court North": {
    "-1": "Concourse",
  },
  "Union Station": {
    "-2": "Foot Court / Retail",
    "-1": "Concourse",
    "0": "Street Level",
  },
};

const readableName = (level: string, buildingName: string) => {
  if (LEVEL_NAMES[buildingName]?.[level]) {
    return LEVEL_NAMES[buildingName]?.[level];
  }
  return level;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const resolveNodes = (nodeIds: Array<NodeID>, data: any) =>
  nodeIds.map((nid) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data.elements.find((e: any) => e.type === "node" && e.id === nid),
  );

export const Direction = ({
  stepGroup,
  nextStepGroup,
}: {
  stepGroup: Array<Step>;
  nextStepGroup?: Array<Step>;
}) => {
  const way = useMemo(() => {
    if (!stepGroup.length) {
      return undefined;
    }
    return wayById(stepGroup[0].wayId);
  }, [stepGroup]);

  const nextWay = useMemo(() => {
    // The next way is either:
    // stepGroup[1], nextStepGroup[0], or null
    if (stepGroup[1]) {
      return wayById(stepGroup[1].wayId);
    }

    if (nextStepGroup?.[0]) {
      return wayById(nextStepGroup[0].wayId);
    }

    return undefined;
  }, [stepGroup, nextStepGroup]);

  const { highlightStep } = useMap();

  useEffect(() => {
    const cleanupFunctions: Array<() => void> = [];

    stepGroup.forEach((step) => {
      const clean = highlightStep(step);
      cleanupFunctions.push(clean);
    });

    // cleanupFunctionhighlightStep(step)

    return () => {
      cleanupFunctions.forEach((clean) => clean());
    };
  }, [highlightStep, stepGroup]);

  const buildings = useMemo(
    () => osmData.elements.filter((e) => e.type === "way" && !!e.tags.building),
    [],
  );

  const fromBuilding = useMemo(() => {
    return buildings.find((b) => {
      if (!stepGroup.length) {
        return undefined;
      }
      const boundingBox = resolveNodes(b.nodes, osmData);
      return isPointInPolygon(
        [stepGroup[0].from.lat, stepGroup[0].from.lon],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        boundingBox.map((n: any) => [n.lat, n.lon]),
      );
    });
  }, [buildings, stepGroup]);

  const toBuilding = useMemo(() => {
    return buildings.find((b) => {
      if (!stepGroup.length) {
        return undefined;
      }
      const boundingBox = resolveNodes(b.nodes, osmData);
      return isPointInPolygon(
        [
          stepGroup[stepGroup.length - 1].to.lat,
          stepGroup[stepGroup.length - 1].to.lon,
        ],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        boundingBox.map((n: any) => [n.lat, n.lon]),
      );
    });
  }, [buildings, stepGroup]);

  const buildingText = useMemo(() => {
    if (
      fromBuilding?.tags?.name &&
      fromBuilding?.tags?.name === toBuilding?.tags?.name
    ) {
      return fromBuilding?.tags?.name;
    }

    const names = [fromBuilding?.tags?.name, toBuilding?.tags?.name].filter(
      Boolean,
    );

    if (!names.length) {
      return "-";
    }

    return names.join(" - ");
  }, [fromBuilding?.tags?.name, toBuilding?.tags?.name]);

  const levelText = useMemo(() => {
    if (way.tags.level && way.tags.level.includes(";")) {
      // Figure out which direction to go
      const levels = way.tags.level.split(";");

      // If we know the level of the next step, show that direction
      if (nextWay && nextWay.tags?.level) {
        if (nextWay.tags.level === levels[0]) {
          return `${readableName(levels[1], buildingText)} → ${readableName(levels[0], buildingText)}`;
        } else {
          return `${readableName(levels[0], buildingText)} → ${readableName(levels[1], buildingText)}`;
        }
      }

      // Get the level of the previous step
      return levels
        .map((lev: string) => readableName(lev, buildingText))
        .join(" and ");
    }

    return readableName(way.tags.level, buildingText) ?? "-";
  }, [way.tags.level, nextWay, buildingText]);

  const stepDataKey = useMemo(() => {
    return [
      last(stepGroup)?.from.id,
      last(stepGroup)?.to.id,
      nextStepGroup?.[0]?.to.id,
    ]
      .filter(Boolean)
      .join("-");
  }, [stepGroup, nextStepGroup]);

  const stepDataImageKey = useMemo(() => {
    return [last(stepGroup)?.from.id, last(stepGroup)?.to.id].join("-");
  }, [stepGroup]);

  const copyStepDataImageKey = useCallback(() => {
    navigator.clipboard.writeText(stepDataImageKey);
  }, [stepDataImageKey]);

  return (
    <div className="info-box">
      <div className="info-box__subdata">
        <div>
          <div className="label">Building</div>
          <div className="value">{buildingText}</div>
        </div>
        {levelText && (
          <div>
            <div className="label">Level</div>
            <div className="value">{levelText}</div>
          </div>
        )}
      </div>
      {/* FOR DEBUGGING / ADDING STEPS */}
      {process.env.NODE_ENV === "development" ? (
        <div>
          {stepDataKey}{" "}
          <a href="#" onClick={copyStepDataImageKey}>
            copy
          </a>
        </div>
      ) : undefined}
      {process.env.NODE_ENV === "development" ||
      STEP_DATA[stepDataKey] ||
      STEP_DATA[stepDataImageKey] ? (
        <img
          style={{
            width: "100%",
            height: "auto",
            maxHeight: "200px",
            aspectRatio: "5/2",
            objectFit: "cover",
          }}
          src={`${process.env.PUBLIC_URL}/images/${stepDataImageKey}.webp`}
        />
      ) : undefined}
      {STEP_DATA[stepDataKey] ?? STEP_DATA[stepDataImageKey] ?? undefined}
    </div>
  );
};
