import { useState, useEffect, useCallback } from "react";
import { Step } from "../../types";
import { ProgressBar } from "../progress-bar/progress-bar.component";
import { track } from "../../services/mixpanel";
import { Direction } from "./direction.component";
import "./style.css";

// Do some massaging of steps to combine some
export const DirectionsBox = ({
  groupedSteps = [],
}: {
  groupedSteps?: Array<Array<Step>>;
}) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setIndex(0); // reset index when we do a new search
  }, [groupedSteps]);

  const onPrevious = useCallback(() => {
    if (index > 0) {
      track("Previous Step Pressed", {
        "Step Navigated To": index - 1,
        "Total Steps": groupedSteps.length,
      });
      setIndex(index - 1);
    }
  }, [index, groupedSteps]);

  const onNext = useCallback(() => {
    if (index < groupedSteps.length - 1) {
      track("Next Step Pressed", {
        "Step Navigated To": index + 1,
        "Total Steps": groupedSteps.length,
      });
      setIndex(index + 1);
    }
  }, [index, groupedSteps.length]);

  return (
    <div className="directions-box__container">
      {groupedSteps.length > 0 && (
        <div className="directions-box">
          <>
            <Direction
              key={`${index}:${groupedSteps[index].length}`}
              stepGroup={groupedSteps[index]}
              nextStepGroup={groupedSteps[index + 1]}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <button
                className="directions-box__button"
                data-testid="previous-step"
                onClick={onPrevious}
                disabled={index === 0}
              >
                ←
              </button>
              {groupedSteps.length > 0 && (
                <div>
                  Step {index + 1} of {groupedSteps.length}
                </div>
              )}
              <button
                className="directions-box__button"
                data-testid="next-step"
                onClick={onNext}
                disabled={index === groupedSteps.length - 1}
              >
                →
              </button>
            </div>
          </>
        </div>
      )}
      {/* <div>Node IDs: {steps[index]?.from.id} - {steps[index]?.to.id}</div> */}
      <ProgressBar progress={((index + 1) / groupedSteps.length) * 100} />
    </div>
  );
};
