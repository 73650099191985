import './style.css';

export const ProgressBar = ({ progress = 0 }: { progress: number }) => {
  return (
    <div className="progress-bar">
      <div
        className="progress-bar__bar"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};
