import { getPath } from "../graph/path-graph";
import { SearchResult } from "../types";

export const getDirections = (from?: SearchResult, to?: SearchResult) => {
  // Get the nearest points, and do dijkstra between them
  const nearestFromPoints = from?.nodeIDs;
  const nearestToPoints = to?.nodeIDs;

  let resolvedPath: Array<string> = [];

  if (nearestToPoints?.length && nearestFromPoints?.length) {
    if (JSON.stringify(nearestFromPoints) === JSON.stringify(nearestToPoints)) {
      return {
        cost: 0,
        path: [],
      };
    }

    // If either to or from is a building, return empty path if the other is in the same building
    if (from?.id.startsWith("building-")) {
      if (to?.subtitle === from?.title) {
        return {
          cost: 0,
          path: [],
        };
      }
    } else if (to?.id.startsWith("building-")) {
      if (from?.subtitle === to?.title) {
        return {
          cost: 0,
          path: [],
        };
      }
    }

    let shortestCost = Infinity;
    let pathForShortestCost;
    let fromPointForShortestCost = nearestFromPoints[0];

    for (const fromPoint of nearestFromPoints) {
      const nodesInPath = getPath(fromPoint, nearestToPoints[0]);

      resolvedPath = "path" in nodesInPath ? nodesInPath.path : nodesInPath;

      // @ts-expect-error pls
      if (nodesInPath.cost < shortestCost && resolvedPath) {
        // @ts-expect-error pls
        shortestCost = nodesInPath.cost;
        pathForShortestCost = resolvedPath;
        fromPointForShortestCost = fromPoint;
      }
    }

    for (const toPoint of nearestToPoints) {
      const nodesInPath = getPath(fromPointForShortestCost, toPoint);

      resolvedPath = "path" in nodesInPath ? nodesInPath.path : nodesInPath;

      // @ts-expect-error pls
      if (nodesInPath.cost < shortestCost && resolvedPath) {
        // @ts-expect-error pls
        shortestCost = nodesInPath.cost;
        pathForShortestCost = resolvedPath;
      }
    }

    return { cost: shortestCost, path: pathForShortestCost };
  }

  // This is needed to handle the starting case (where no from/to are chosen yet)
  return { cost: undefined, path: undefined };
};
