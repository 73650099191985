import { PREFERRED_WALKING_SPEED } from "../constants";

export const getFormattedTimeForDistance = (distanceInMetres: number) => {
  const minutes = distanceInMetres / PREFERRED_WALKING_SPEED / 60;

  if (minutes <= 1) {
    return "Less than a minute";
  } else if (Math.round(minutes) === 1) {
    return `About ${Math.round(minutes)} minute`;
  }

  return `About ${Math.round(minutes)} minutes`;
}
