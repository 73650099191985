import osmData from "../data/osm/processed.json";
import graphDescription from "../data/computed/graph-description.json";
import distanceFrom from "distance-from";
import Graph from "node-dijkstra";
import { NodeID } from "../types";

const graph = new Graph(graphDescription);

export const getPath = (fromId: string, toId: string) =>
  graph.path(fromId, toId, { cost: true });

export const waysAsGeoJson = (data: typeof osmData) => {
  // Go through the ways
  const ways = data.elements.filter(
    (e) => e.type === "way" && !!e.tags?.highway,
  );

  return ways.map((way) => {
    return way.nodes?.map((nodeId: NodeID) => {
      const node = data.elements.find(
        (el) => el.type === "node" && `${el.id}` === `${nodeId}`,
      );
      return [node?.lon, node?.lat];
    });
  });
};

// TODO: Compute this up front so that we don't have to do this on the client side
export const allWaysGeoJson = waysAsGeoJson(osmData);

const amenitiesAsGeoJson = (data: typeof osmData) => {
  const amenities = data.elements.filter(
    (e) => e.type === "node" && !!e.tags?.amenity,
  );

  return amenities.map((a) => ({
    title: a.tags?.name,
    coords: [a.lon, a.lat],
    id: a.id,
  }));
};

export const allAmenitiesGeoJson = amenitiesAsGeoJson(osmData);

export const getNearestNodeIdFromLatLng = (
  [lat, lon]: [lat: number, lon: number],
  level?: string,
): number | undefined => {
  // returns the node ID
  const ways = osmData.elements.filter(
    (e) => e.type === "way" && !!e.tags?.highway,
  );

  let shortestNodeId;
  let shortestDistance = Infinity;

  // loop through all the ways
  for (const way of ways) {
    // loop through all the way's nodes
    for (const nodeId of way?.nodes ?? []) {
      // get the distance from the given latlng
      const node = osmData.elements.find((e) => e.id === nodeId);
      const nodeLevel = node.tags?.level;
      if (node?.lat && node?.lon) {
        // If the amenity's node is on a way, that's the node to travel from
        if (node.lat === lat && node.lon === lon) {
          return node.id;
        }
        const distance = distanceFrom([node.lat, node.lon])
          .to([lat, lon])
          .in("m");
        if (
          distance < shortestDistance &&
          (nodeLevel && level ? level === nodeLevel : true)
        ) {
          shortestDistance = distance;
          shortestNodeId = node.id;
        }
      }
    }
  }

  return shortestNodeId;
};
