const position = [-79.38151, 43.647572] as [number, number];
const bounds = [
  [-79.39, 43.64], // Southwest coordinates
  [-79.35, 43.66], // Northeast coordinates
] as [[number, number], [number, number]];

export const MAP_CONFIG = {
  container: "map",
  style: "mapbox://styles/abejfehr/clsjlvtli03nu01o8357rgblx",
  center: position,
  zoom: 15.5,
  maxBounds: bounds,
};

export const COLORS = {
  BLACK: "#2E282A",
  WHITE: "#EBEBEB",
  GREEN: "#FF4B3E",
  LIGHTGRAY: "#D9DCD6",
  DARKGRAY: "#6B717E",
};

// from wikipedia, in metres per second
export const PREFERRED_WALKING_SPEED = 1.42;
